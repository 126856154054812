// dealerfiledetails.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Config from '../config.json';

/**
 * DealerFileDetails
 * This page fetches a single dealer-file document by its ID, plus *all* related
 * american_finance_accounts records (matched by dealer_file_id). It displays:
 *    - Certain columns by default.
 *    - "Date Completed" and "Match" columns are hidden by default.
 *    - A small toggle button shows/hides these extra columns.
 */
const DealerFileDetails = () => {
    const navigate = useNavigate();
    const { fileId } = useParams();
    const [fileData, setFileData] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [showExtraColumns, setShowExtraColumns] = useState(false); // Toggles extra columns

    /**
     * Returns a blank string if the value is strictly 0; otherwise returns the original value.
     */
    const blankIfZero = (value) => {
        return value === 0 ? "" : value;
    };

    useEffect(() => {
        const fetchFileData = async () => {
            setStatusMessage("Loading dealer file details...");
            try {
                // Decide the URL based on hostname
                const apiURL = window.location.hostname === "192.168.1.45"
                    ? `http://localhost:8383/loanverification/dealerfile/${fileId}`
                    : `https://api.galeforceai.com/loanverification/dealerfile/${fileId}`;

                const response = await fetch(apiURL, {
                    method: "GET",
                    headers: {
                        "Authorization": Config.loanverificationrequest
                    }
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch dealer file details");
                }

                const data = await response.json();
                setFileData(data);
                setStatusMessage("");
            } catch (error) {
                setErrorMessage(error.message);
                setStatusMessage("");
            }
        };

        fetchFileData();
    }, [fileId]);

    const handleSendSMS = async () => {
        setStatusMessage("Sending SMS messages...");
        try {
            // Updated API endpoint to include fileId
            const apiURL = window.location.hostname === "192.168.1.45"
                ? `http://localhost:8383/loanverification/sendsms/${fileId}`
                : `https://api.galeforceai.com/loanverification/sendsms/${fileId}`;

            const response = await fetch(apiURL, {
                method: "POST",
                headers: {
                    "Authorization": Config.loanverificationrequest
                }
            });

            if (!response.ok) {
                throw new Error("Failed to send SMS messages");
            }

            const data = await response.json();
            setStatusMessage(data.message);
        } catch (error) {
            setErrorMessage(error.message);
            setStatusMessage("");
        }
    };

    if (errorMessage) {
        return <div style={{ color: "red" }}>{errorMessage}</div>;
    }

    if (!fileData) {
        return null; // No data yet
    }

    return (
        <div>
            <div style={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '1rem'
            }}>
                <h1 className="page-header" style={{ margin: 0 }}>Dealer File Accounts</h1>
                <button
                    onClick={() => navigate(-1)}
                    style={{
                        fontSize: '24px',
                        backgroundColor: '#f44336', 
                        color: '#fff',
                        cursor: 'pointer',
                        padding: '0.25rem 0.5rem',
                        border: 'none',
                        borderRadius: '4px'
                    }}
                >
                    X
                </button>
            </div>

            <div style={{ marginBottom: "0.5rem" }}>
                <strong>Dealer Name:</strong> {fileData.dealerName}
            </div>
            <div style={{ marginBottom: "0.5rem" }}>
                <strong>File Name:</strong> {fileData.fileName}
            </div>
            <div style={{ marginBottom: "0.5rem" }}>
                <strong>Status:</strong> {fileData.status}
            </div>
            <div style={{ marginBottom: "0.5rem" }}>
                <strong>Upload Date:</strong> {fileData.uploadDate}
            </div>

            <hr />

            <button
                onClick={() => {
                    if (window.confirm('Are you sure you want to send SMS messages to all borrowers?')) {
                        handleSendSMS();
                    }
                }}
                className="btn btn-primary"
                style={{
                    marginBottom: "1rem",
                    marginRight: "1rem",
                    width: "200px"
                }}
            >
                Send SMS to All Borrowers
            </button>
            
            <button
                onClick={() => setShowExtraColumns(prev => !prev)}
                className="btn btn-primary"
                style={{
                    marginBottom: "1rem",
                    width: "200px"
                }}
            >
                {showExtraColumns ? "Hide Extra Columns" : "Show Extra Columns"}
            </button>
            
            {statusMessage && 
                <div style={{ color: "green" }}>
                    {statusMessage}<br /><br />
                </div>
            }

            {/* If there are any accounts, render a table; otherwise, indicate none */}
            {fileData.accounts && fileData.accounts.length > 0 ? (
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                    <thead>
                        <tr>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Account #</th>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>First Name</th>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Last Name</th>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Cell #</th>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Home #</th>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Work #</th>
                            
                            {showExtraColumns && (
                                <th style={{ border: "1px solid #ccc", padding: "8px" }}>Date Completed</th>
                            )}

                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Email</th>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Comments</th>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Buy</th>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Frequency</th>

                            {showExtraColumns && (
                                <th style={{ border: "1px solid #ccc", padding: "8px" }}>Inbound Phone #</th>
                            )}
                            
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Color</th>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Year</th>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Make</th>
                            
                            {showExtraColumns && (
                                <th style={{ border: "1px solid #ccc", padding: "8px" }}>Match</th>
                            )}

                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Model</th>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Payment Amt</th>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Principal</th>

                            {showExtraColumns && (
                                <>
                                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Updated Cell</th>
                                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Updated Email</th>
                                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Updated Home</th>
                                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>Updated Work</th>
                                </>
                            )}

                            {showExtraColumns && (
                                <th style={{ border: "1px solid #ccc", padding: "8px" }}>Where Contacted</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {fileData.accounts.map((acct, index) => (
                            <tr key={index}>
                                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{blankIfZero(acct.accountNumber)}</td>
                                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{acct.firstName}</td>
                                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{acct.lastName}</td>
                                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{blankIfZero(acct.cellNo)}</td>
                                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{blankIfZero(acct.homeNo)}</td>
                                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{blankIfZero(acct.workNo)}</td>
                                
                                {showExtraColumns && (
                                    <td style={{ border: "1px solid #ccc", padding: "8px" }}>{acct.dateCompleted}</td>
                                )}

                                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{blankIfZero(acct.emailAddress)}</td>
                                {/* <td style={{ border: "1px solid #ccc", padding: "8px" }}>{acct.comments}</td> */}
                                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{acct.diagnosis}</td>
                                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{blankIfZero(acct.buy)}</td>
                                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{acct.frequency}</td>

                                {showExtraColumns && (
                                    <td style={{ border: "1px solid #ccc", padding: "8px" }}>{acct.inboundPhoneNo}</td>
                                )}
                                
                                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{acct.color}</td>
                                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{blankIfZero(acct.year)}</td>
                                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{acct.make}</td>
                                
                                {showExtraColumns && (
                                    <td style={{ border: "1px solid #ccc", padding: "8px" }}>{acct.match}</td>
                                )}

                                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{acct.model}</td>
                                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{blankIfZero(acct.pmtAmt)}</td>
                                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{blankIfZero(acct.principal)}</td>

                                {showExtraColumns && (
                                    <>
                                        <td style={{ border: "1px solid #ccc", padding: "8px" }}>{acct.updatedCell}</td>
                                        <td style={{ border: "1px solid #ccc", padding: "8px" }}>{acct.updatedEmail}</td>
                                        <td style={{ border: "1px solid #ccc", padding: "8px" }}>{acct.updatedHome}</td>
                                        <td style={{ border: "1px solid #ccc", padding: "8px" }}>{acct.updatedWork}</td>
                                    </>
                                )}

                                {showExtraColumns && (
                                    <td style={{ border: "1px solid #ccc", padding: "8px" }}>{acct.whereContacted}</td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No associated accounts found.</p>
            )}
        </div>
    );
};

export default DealerFileDetails;