const administrator = localStorage.getItem('user_id')?.includes("@galeforce.ai");

const Menu = [
	{ is_header: true, title: 'Navigation' },
	
	...(administrator
	  ? [
		  { path: '/aicrm', icon: 'bi bi-cash-coin', title: 'AI CRM' },
		  { path: '/chat/usarmy', icon: 'bi bi-lightbulb', title: 'Subject Matter AI' },
		  { path: '/artemisai', icon: 'bi bi-heart-arrow', title: 'Artemis AI' },
		]
	  : []
	),
	
	{ path: '/loanverification/dealerfiles', icon: 'bi bi-flag', title: 'Loan Verification' },
	{ path: '/redflagai', icon: 'bi bi-flag', title: 'Red Flag AI' },
	{ path: '/settings', icon: 'bi bi-gear', title: 'Settings' },
  ];
  
  export default Menu;